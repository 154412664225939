<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="isLoadingPage"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    :disabledSaveButton="isEqual"
    @save="onSaveForm"
    @cancel="onCancel('TelemarketingQuestions')"
  >
    <template #answers>
      <div class="answers">
        <p class="editable-form__description answers__description">Ответы:</p>
        <div class="input__wrapper">
          <div
            v-for="(item, index) in form.answers"
            :key="item.id"
            class="answers__item"
          >
            <span>{{ index + 1 }}.</span>
            <sm-input
              v-model="form.answers[index].answer"
              type="text"
              label="Ответ"
            />
            <sm-input
              v-model="form.answers[index].nextQuestionNumber"
              type="number"
              label="Номер вопроса, на который нужно перейти после этого ответа"
            />
            <button
              v-show="form.answers.length"
              class="answers__delete-button"
              @click="openDeleteAnswersModal(index)"
            >
              <sm-icon name="trash" />
            </button>
          </div>
          <button class="answers__add-button" @click="onAddAnswer">
            <sm-icon name="plus" />
            Добавить пункт
          </button>
        </div>
      </div>
    </template>
  </sm-editable-item>
</template>

<script>
// mixins
import editableListItemEdit from '@/mixins/editableListItemEdit.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';
import SmIcon from '@/components/common/SmIcon.vue';
import SmInput from '@/components/common/forms/SmInput.vue';

export default {
  name: 'TelemarketingQuestionsEdit',

  components: {
    SmEditableItem,
    SmIcon,
    SmInput,
  },

  mixins: [editableListItemEdit],

  data() {
    return {
      isLoadingPage: false,
      controllerName: 'TelemarketingQuestions',
      pageHeader: 'Редактирование вопроса по телемаркетингу',
      breadcrumbs: [
        {
          text: 'Телемаркетинг',
          route: { name: 'TelemarketingMain' },
        },
        {
          text: 'Вопросы по телемаркетингу',
          route: { name: 'TelemarketingQuestions' },
        },
        {
          text: 'Редактирование вопроса по телемаркетингу',
        },
      ],
      form: {},
    };
  },

  computed: {
    ...mapState({
      item: (state) => state.editableList.item,
      telemarketingScripts: (state) => state.common.telemarketingScripts,
    }),

    fields() {
      return [
        {
          groups: [
            {
              form: [
                {
                  type: 'select',
                  key: 'scriptId',
                  label: 'Скрипт',
                  list: this.telemarketingScripts.data,
                },
                {
                  type: 'textarea',
                  key: 'question',
                  label: 'Вопрос',
                },
                {
                  type: 'integerNumber',
                  key: 'number',
                  label:
                    'Номер вопроса (для ссылок перехода к следующему вопросу)',
                },
                {
                  type: 'text',
                  key: 'answers',
                  label: 'Варианты ответов',
                },
                {
                  type: 'checkbox',
                  key: 'multipleAnswers',
                  label: 'Разрешены множественные ответы',
                },
              ],
            },
          ],
        },
      ];
    },

    initialAnswersForm() {
      return {
        id: this.form?.answers?.length + 1,
        answer: null,
      };
    },
  },

  created() {
    this.isLoadingPage = true;

    const telemarketingScripts = this.getCommonList({
      name: 'TelemarketingScripts',
    });

    const item = this.getItem({
      name: this.controllerName,
      id: this.id,
    }).then(() => {
      this.form = this.lodash.cloneDeep(this.item.data);
    });

    Promise.all([telemarketingScripts, item]).finally(() => {
      this.isLoadingPage = false;
    });
  },

  methods: {
    ...mapActions({
      getCommonList: 'common/getCommonList',
      getItem: 'editableList/getItem',
      downloadFile: 'editableList/downloadFile',
      updateItem: 'editableList/updateItem',
    }),

    onAddAnswer() {
      this.form.answers.push(this.lodash.cloneDeep(this.initialAnswersForm));
    },

    openDeleteAnswersModal(index) {
      this.$root
        .$confirmModal({
          message: 'Удалить пункт?',
          leftButtonText: 'Да',
          rightButtonText: 'Нет',
        })
        .then((response) => {
          if (response) {
            this.form.answers.splice(index, 1);
          }
        });
    },

    onSaveForm() {
      const { answers } = this.form;

      this.form.answers = answers.map(answer => {
        return {
            ...answer,
            nextQuestionNumber: +answer.nextQuestionNumber
        };
      });

      this.onSave('TelemarketingQuestions');
    },
  },
};
</script>
<style lang="scss" scoped>
.answers {
  width: 100%;

  display: flex;
  align-items: flex-start;
}

.answers__description {
  align-self: flex-start;
}

.answers__item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  .input__wrapper {
    margin-left: 10px;
  }
}

.answers__delete-button {
  color: var(--gray);
  margin-left: 10px;
  &:hover {
    color: var(--red);
  }
}

.answers__add-button {
  display: flex;
  align-items: center;
  font-weight: 500;
  line-height: 1;
  color: var(--gray);
  border: none;
  background: transparent;
  transition: color 0.3s;
  cursor: pointer;
  margin: 0 0 30px 0;
  &:hover {
    color: var(--blue);
  }
}
</style>
